import React from 'react';
import './App.css';
import ParallaxImage, { defaultParallaxStrength } from './components/ParallaxImage';
import { Parallax } from 'react-parallax';

function App() {
  return (
    <div>
      <ParallaxImage
        src="images/Chapel Hills Jewelry June 2019 Whirl Magazine Tacori.jpeg"
        textContent={
          <>
            This is a print magazine ad that I art directed for Tacori,
            while employed by Beyond Spots & Dots Advertising Agency.
            This was printed in local and national magazines,
            as well as being repurposed for posters and print ads for local Pittsburgh jewelry stores selling Tacori products.
          </>
        }
      />
      <ParallaxImage
        src="images/Chapel Hills Jewelry Mach 2019 Whirl Magazine Tacori.jpeg"
        textContent={
          <>
            Here is the second in a series of two print advertisements I art directed for Tacori in 2013.
          </>
        }
      />
      <ParallaxImage
        src="images/Chat Fancy Episode Art.jpeg"
        scale={.5}
        textContent={
          <>
            I designed this logo for a podcast. It's kind of fun.
          </>
        }
      />
      <ParallaxImage
        src="images/Creators' Cafe Podcast Logo .jpeg"
        scale={.5}
        textContent={
          <>
            Here's a logo I designed for another podcast.
          </>
        }
      />
      <ParallaxImage
        src="images/Land Rover Monroeville Web Ad - UK .jpeg"
        textContent={
          <>
            I'm very proud of this "Passport To Adventure" campaign which I was privileged to design
            under the Art Direction of Andreas Beck of Beyond Spots & Dots Pittsburgh.
          </>
        }
        textContentBox
      />
      <ParallaxImage
        src="images/Latter-Day Cafe Logo + Photography.png"
        textContent={
          <>
            Here's a sample of my art direction at work in photography and brand identity.
            In addition to designing the logo, and cup design -- I also arranged this photoshoot
            to highlight the beauty of Latter Day Cafe's Pink Drink.
            It was well received in print and on social media.
          </>
        }
      />
      <ParallaxImage
        src="images/Lobster Mobile Hero Framework LobBites Platter LJS 2018.png"
        textContent={
          <>
            This multi-platform campaign for Long John Silver's included digital advertisements,
            print advertisements, and in-store posters for Long John Silver's restaurants.
            It was a really enlightening experience to help Long John Silver's move their brand in a
            more "modern nantucket" and "luxury in Maine" direction,
            after having been stuck in the "discount seafood" market for so long.
          </>
        }
        textContentBox
      />
      <ParallaxImage
        src="images/Pittsburgh Wine and Spirits Festival 2022 Web Ad .png"
      />
      {/*
      <ParallaxImage
        src="images/Wine and Spirits Thumbnail- 2022  -PGH magazine .png"
        scale={.8}
      />
      */}
      <ParallaxImage
        src="images/Revisiting Sunnydale Logo.jpeg"
      />
      <ParallaxImage
        src="images/Revisiting Sunnydale Episode Art .jpeg"
      />
      <ParallaxImage
        src="images/Web-Ad-LJS-2018 - cropped.jpeg"
        scale={.8}
      />
      <ParallaxImage
        src="images/Rugged Angel.png"
      />
      <ParallaxImage
        src="images/Slippery Rock University logo.jpeg"
        scale={.9}
      />
      <ParallaxImage
        src="images/Slippery Rock University photo.jpeg"
      />
      <ParallaxImage
        src="images/SHARK6Blue.jpg"
      />
      <ParallaxImage
        src="images/Jaguar Monroeville.png"
      />
      <ParallaxImage
        src="images/Better Dental facebook.png"
      />
      {/*
      <ParallaxImage
        src="images/Better Dental collage.png"
      />
      */}
      <ParallaxImage
        src="images/Better Dental pen.jpeg"
      />
      <ParallaxImage
        src="images/Better Dental thread.jpeg"
        scale={.8}
      />
      <ParallaxImage
        src="images/Peppers homepage.png"
      />
      <ParallaxImage
        src="images/Peppers photos page.png"
      />
      <ParallaxImage
        src="images/A and W.jpeg"
        scale={.95}
        textContent={
          <>
            When A&W asked my employer, Beyond Spots & Dots to design a new logo in 2013,
            I was faced with a tough decision. As the Senior Graphic Designer, I made the decision
            to advise the client that a logo redesign was NOT in their best interest.
            Although it was difficult, I sacrificed a lucrative opportunity because I did not feel it
            was in the client's best interest. Instead, I encouraged A&W to focus on product development
            and improving the quality of their food photography. The resulting print ads/coupon
            campaign improved sales 12% in the western states where the campaign was targeted.
          </>
        }
        textContentBox
      />
      <ParallaxImage
        src="images/Jaguar Hero 2012 Alive Campaign.jpeg"
      />
      <ParallaxImage
        src="images/s-l1600.jpg"
      />
      <ParallaxImage
        src="images/Pittsburgh Wine Festival 2018 - Original Illustration - Poster.png"
        textContent={
          <>
            Here's a fun poster I made while serving as the Art Director for the Pittsburgh Wine Festival
            under the direction of JP Russell IV.
          </>
        }
        textContentBox
      />
      <Parallax
        style={{
          height: '85vh',
        }}
        strength={defaultParallaxStrength}
      >
        <div style={{
          fontSize: '1.5em',
          fontFamily: 'arial',
          textAlign: 'center',
        }}>
          <p>Secretina Ruth Draper</p>
          <p><a href="https://upwork.com/freelancers/~01d30c553602682660" target="_blank" rel="noreferrer">Upwork Profile</a></p>
        </div>
      </Parallax>
    </div>
  );
}

export default App;
