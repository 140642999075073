import { Parallax } from 'react-parallax'

export const defaultParallaxStrength = 500

const ParallaxImage = (
  props: {
    src: string
    scale?: number
    textContent?: React.ReactNode | string
    textContentBox?: boolean
    textPosition?: 'left' | 'right'
    content?: React.ReactNode | string
  }
)=> {
  return (
    <Parallax
      bgImage={props.src}
      bgImageStyle={{
        width: `${(props.scale ?? 1) * 100}vw`,
        height: `${(props.scale ?? 1) * 100}vh`,
        ...props.scale !== undefined && {
          top: `${(1 - props.scale) / 2 * 100}vh`,
        },
        objectFit: 'contain',
      }}
      style={{
        width: '100vw',
        height: '100vh',
      }}
      strength={defaultParallaxStrength}
    >
      {props.textContent && (
        <div
          style={{
            display: 'flex',
            width: '50vw',
            height: '100vh',
            float: 'right',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '39%',
              position: 'relative',
              left: '10%',
              height: '100%',
              alignItems: 'center',
              textAlign: 'left',
              padding: '1.2vw',
              //padding: '15px',
              justifyItems: 'center',
              ...(props.textPosition ?? 'right') === 'right' && {
                float: 'right',
              },
              fontFamily: 'helvetica',
              fontSize: '0.9vw',
              overflow: 'auto',
              color: 'black',
              //textShadow: 'white 1px 0px 5px',
            }}
          >
            <div
              style={{
                ...props.textContentBox && {
                  backgroundColor: 'rgba(255, 255, 255, .6)',
                  //padding: '1.2vw',
                  padding: '15px',
                  border: '1px solid black',
                },
              }}
            >
              {props.textContent}
            </div>
          </div>
        </div>
      )}
      {props.content}
    </Parallax>
  )
}

export default ParallaxImage
